






























































































































































































































import Vue from 'vue';
import Map from './components/Map.vue';
import Modal from './components/Modal.vue';
import DepartmentsDropdown from './components/DepartmentsDropdown.vue';
import RelationDropdown from './components/RelationDropdown.vue';
import Axios from 'axios';

export default Vue.extend({
    name: 'Main',
    components: {
        Map,
        Modal,
        DepartmentsDropdown,
        RelationDropdown,
    },
    data() {
        return {
            maxSelectedDepartments: 8, //Number maximum of departements that can be selected
            toggleBelgium: true, //Toggle beetween france or belgium map
            currentStep: 1,
            showModalConditions: false,
            selectedDepartments: [] as any[],
            contactForm: {
                lastName: '',
                name: '',
                email: '',
                phone: '',
                relation: '',
                conditions: '',
            },
            contactFormInvalidField: [] as any,
        };
    },
    computed: {
        disabledGoToStep2: function (): any {
            return !this.selectedFranceDepartments.length && !this.selectedBelgiumDepartments.length;
        },
        disabledSendForm: function (): any {
            return (
                !this.contactForm.lastName ||
                !this.contactForm.name ||
                !this.contactForm.email ||
                !this.contactForm.phone ||
                !this.contactForm.conditions ||
                !this.contactForm.relation
            );
        },
        fullName: function (): string {
            return `${this.contactForm.lastName} ${this.contactForm.name},`;
        },
        selectedFranceDepartments: function (): any {
            let department = this.selectedDepartments
                .filter((department) => {
                    if (department.isFrance) {
                        return true;
                    }
                })
                .map((d) => d.uniqueId);
            return department || [];
        },
        selectedBelgiumDepartments: function (): any {
            let department = this.selectedDepartments
                .filter((department) => {
                    if (!department.isFrance) {
                        return true;
                    }
                })
                .map((d) => d.uniqueId);
            return department || [];
        },
    },
    methods: {
        departmentClicked(selectedDepartment: any) {
            if (
                selectedDepartment.isFrance &&
                this.selectedFranceDepartments.indexOf(selectedDepartment.uniqueId) != -1
            ) {
                this.selectedDepartments = this.selectedDepartments.filter(
                    (department) => department.uniqueId !== selectedDepartment.uniqueId
                );
                (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
                return;
            }

            if (
                !selectedDepartment.isFrance &&
                this.selectedBelgiumDepartments.indexOf(selectedDepartment.uniqueId) != -1
            ) {
                this.selectedDepartments = this.selectedDepartments.filter(
                    (department) => department.uniqueId !== selectedDepartment.uniqueId
                );
                (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
                return;
            }

            if (this.selectedDepartments.length < this.maxSelectedDepartments) {
                this.selectedDepartments.push(selectedDepartment);
                (this.$refs.departmentMap! as any).toggleDepartement(selectedDepartment);
                return;
            }
        },
        checkIfFormIsValid() {
            this.contactFormInvalidField = [];
            if (!this.disabledSendForm) {
                let frenchRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
                let belgiumRegex = /^(?:(?:\+|00)(32|30)|0)[1-9]{8,12}$/;

                if (!this.contactForm.phone.match(frenchRegex) && !this.contactForm.phone.match(belgiumRegex)) {
                    this.contactFormInvalidField.push('phone');
                }
                let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!this.contactForm.email.match(emailRegex)) {
                    this.contactFormInvalidField.push('email');
                }

                if (!this.contactFormInvalidField.length) {
                    return true;
                }
            }

            return false;
        },
        async sendForm() {
            if (this.checkIfFormIsValid()) {
                let url = 'https://urgence-maison-de-retraite.fr/apiForm.php';
                let ipUser = await Axios.get('https://api.ipify.org');

                // this.$gtag.event('conversion', { send_to: 'AW-699900319/xop9CKSu5KcDEJ_D3s0C' });

                Axios.get(url, {
                    params: {
                        inputDep: this.selectedDepartments.map((d) => `${d.name}`).join(','), // Name1,Name2
                        cnom: this.contactForm.lastName,
                        cprenom: this.contactForm.name,
                        cemail: this.contactForm.email,
                        contactlink: this.contactForm.relation, // relation.name
                        cphone: this.contactForm.phone,
                        consentementVal: this.contactForm.conditions ? 1 : 0, // 0 or 1
                        source: 'GABF108',
                        emailAR: 'lead70',
                        userip: ipUser.data, // get IP from client
                    },
                }).then(
                    () => {
                        //Success
                        console.log('Success');
                this.currentStep = 3;
                    },
                    () => {
                        //Error
                        console.log('Error');
                    }
                );
            }
        },
    },
});
